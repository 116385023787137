import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const data = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          defaultTitle: title
          titleTemplate
          defaultDescription: description
          siteUrl: siteUrl
        }
      }
      defaultImage: file(relativePath: { eq: "backgrounds/tiarna-og.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `)

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
  } = data.site.siteMetadata

  const defaultImage = getSrc(data.defaultImage)

  const SEO = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={SEO.title} titleTemplate={titleTemplate}>
      <meta name="description" content={SEO.description} />
      <meta name="image" content={SEO.image} />

      {SEO.url && <meta property="og:url" content={SEO.url} />}

      {(article ? true : null) && <meta property="og:type" content="article" />}

      {SEO.title && <meta property="og:title" content={SEO.title} />}

      {SEO.description && (
        <meta property="og:description" content={SEO.description} />
      )}

      {SEO.image && <meta property="og:image" content={SEO.image} />}

      <meta name="twitter:card" content="summary_large_image" />

      {SEO.title && <meta name="twitter:title" content={SEO.title} />}

      {SEO.description && (
        <meta name="twitter:description" content={SEO.description} />
      )}

      {SEO.image && <meta name="twitter:image" content={SEO.image} />}

      <script type="application/ld+json">
        {`
          {
            "@context" : "http://schema.org",
            "@type" : "LocalBusiness",
            "name" : "Tiarna Real Estate Services, Inc.",
            "openingHours": "Mo,Tu,We,Th,Fr 09:00-17:00",
            "telephone" : "949-833-8334",
            "address" : {
              "@type" : "PostalAddress",
              "streetAddress" : "2603 Main Street, Suite 210",
              "addressLocality" : "Irvine",
              "addressRegion" : "CA",
              "postalCode" : "92614"
            },
            "image" : "${SEO.image}",
            "priceRange" : "$"
          }
        `}
      </script>
    </Helmet>
  )
}

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}